<template>
  <v-expand-transition>
    <v-card
      flat
      style="position: relative;"
      v-show="readyForDisplay"
    >
      <portal to="videoTimer">
        <video-timer v-if="inviteeConnected" />
      </portal>
      <portal to="videoPopupTimerSlot">
        <video-timer :slim="true" v-if="inviteeConnected" />
      </portal>
      <v-card
        rounded="lg"
        flat
        id="remote-media-div"
        :min-height="minHeightOfVideoWindow"
        color="black"
        style="object-fit: contain; position: relative; margin-top: -.5%; margin-bottom: -4.5%"
      >
      </v-card>

      <v-fade-transition>
        <v-overlay
          absolute
          :value="showOverlay"
          color="black"
          :z-index="0"
          v-show="showTools"
        >
          <ConnectionProgressIndicator
            :inviteeConnected="inviteeConnected"
            :inviteeDisconnectedFirst="inviteeDisconnectedFirst"
            :inviteeURL="inviteeURL"
            :inviteeToken="inviteeToken"
            :patient="patient"
            :remoteVideo="remoteVideoEnabled"
            :remoteAudio="remoteAudioEnabled"
            :errorMessage="roomErrorMessage"
            :stage="stage"
            @link:click="linkUrl => $emit('link:click', linkUrl)"
          />
        </v-overlay>
      </v-fade-transition>

      <v-scale-transition origin="center">
        <div
          v-show="showTools && selfVideoReady && !miniView"
          style="position: absolute; top:10px; left: 10px;"
          id="local"
        ></div>
      </v-scale-transition>

      <v-row
        style="position: absolute; bottom: 4.5%; width:100%; height:100px;"
        align="center"
        justify="center"
        class="px-3 py-4 flex"
        no-gutters
      >
        <v-scale-transition origin="center">
          <v-btn
            v-show="showTools && !miniView"
            @click="toggleLocalVideo"
            class="mx-2"
            fab
            :small="!isMobile"
            :color="videoButtonColor"
            :dark="!localVideoEnabled"
          >
            <v-icon v-if="localVideoEnabled">mdi-video</v-icon>
            <v-icon v-else>mdi-video-off</v-icon>
          </v-btn>
        </v-scale-transition>

        <v-btn
          v-show="showTools && !miniView"
          @click="leaveRoom"
          class="mx-2"
          fab
          dark
          color="red"
          :small="!isMobile"
        >
          <v-icon dark>mdi-close</v-icon>
        </v-btn>

        <v-scale-transition origin="center">
          <v-btn
            v-show="showTools && !miniView"
            @click="toggleLocalAudio"
            class="mx-2"
            fab
            :dark="!localAudioEnabled"
            :small="!isMobile"
            :color="audioButtonColor"
          >
            <v-icon v-if="localAudioEnabled">mdi-microphone</v-icon>
            <v-icon v-else>mdi-microphone-off</v-icon>
          </v-btn>
        </v-scale-transition>
      </v-row>
    </v-card>
  </v-expand-transition>

  <!-- call manageemnt buttons-->

  <!-- <div style="position: fixed; left: 2px; top: 2px; color: white; font-size: 10px; background-color: #00000080; padding:5px;">
    <div>Room Connected: {{connectedToRoom}}</div>
    <div>
    Room ID: {{roomInfo}}
    </div>
    <div>
    Local Audio Connected: {{localAudioEnabled}}
  </div>
  <div>
    Local Video Connected: {{localVideoEnabled}}
  </div>
  <div>
    Guest: {{participantCount}}
  </div>
  <div>
    Remote Audio Connected: {{remoteAudioEnabled}}
  </div>
  <div>
    Remote Video Connected: {{remoteVideoEnabled}}
  </div>
  </div> -->
</template>

<script>
const { connect, createLocalTracks } = require('twilio-video')

import ConnectionProgressIndicator from './ConnectionProgressIndicator.vue'
import { mapState } from 'vuex'
import get from 'lodash/get'
import VideoTimer from './VideoTimer.vue'

export default {
  props: {
    roomToken: null,
    inviteeURL: null,
    inviteeToken: null,
    expandToFillScreen: {
      default: false,
    },
    miniView: {
      default: false,
    },
  },
  components: {
    ConnectionProgressIndicator,
    VideoTimer,
  },
  data: () => ({
    videoSessionStartTimestamp: null,
    readyForDisplay: false,
    showTools: false,
    callEnded: false,
    selfVideoReady: false,
    room: null,
    showSnackbar: false,
    snackbarText: null,
    snackbarType: null,
    localVideoEnabled: false,
    localAudioEnabled: false,
    remoteVideoSubscribed: false,
    remoteAudioSubscribed: false,
    remoteVideoEnabled: false,
    remoteAudioEnabled: false,
    inviteeConnected: false,
    roomErrorMessage: null,
    videoResizeObserver: null,
    inviteeDisconnectedFirst: false,
  }),
  watch: {
    remoteVideoEnabled() {
      // either someone joined to reenabled their video. you should check to see if the orientation should be updated

      let vidContainer = document.getElementById('remote-media-div')

      if (
        vidContainer.width !== null &&
        vidContainer.width > vidContainer.height
      ) {
        this.$emit('landscapeOrientation')
      } else if (this.remoteVideoEnabled) {
        this.$emit('portraitOrientation')
      }
    },
    inviteeConnected(val) {
      this.$emit('inviteeConnected', val)
    },
    readyForDisplay() {
      // delay the appearance of some of the items until the card has had the chance to expand

      let vm = this
      setTimeout(() => {
        vm.showTools = true
      }, 400)
    },
  },
  computed: {
    ...mapState('patient', ['patient']),
    ...mapState('auth', ['user']),
    containerStyle() {
      if (this.expandToFillScreen) {
        return 'position: relative; height:90vh'
      } else {
        return 'position: relative;'
      }
    },
    showOverlay() {
      // show the overlay if the participant is muted, or participant video is off
      if (this.showTools) {
        if (!this.inviteeConnected) {
          return true
        }

        if (!this.remoteVideoEnabled) {
          return true
        }

        if (!this.remoteAudioEnabled) {
          return true
        }

        if (this.inviteeDisconnectedFirst) {
          return true
        }
      }
      return false
    },
    minHeightOfVideoWindow() {
      if (this.remoteVideoEnabled) {
        return 50
      }

      return 400
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown || false
    },
    progress() {
      if (this.room) {
        if (this.room.state === 'connected') {
          if (this.inviteeConnected) {
            return 100
          }
          return 20
        }
        return 10
      }

      return 0
    },
    stage() {
      if (this.room) {
        if (this.room.state === 'connected') {
          if (this.inviteeConnected) {
            if (this.remoteAudioSubscribed && this.remoteVideoSubscribed) {
              return 3
            }
            return 2
          }
        }
        return 1
      }

      return 0
    },
    connectedToRoom() {
      if (this.room) {
        return this.room.state === 'connected'
      }
      return false
    },
    roomInfo() {
      if (this.room) {
        return this.room.name
      }

      if (this.roomErrorMessage) {
        return this.roomErrorMessage
      }
      return 'not connected'
    },
    videoButtonColor() {
      if (this.localVideoEnabled) {
        return 'grey darken-3'
      }
      return 'red'
    },
    audioButtonColor() {
      if (this.localAudioEnabled) {
        return 'grey darken-3'
      }
      return 'red'
    },
  },
  methods: {
    toast(type, text) {
      this.showSnackbar = true
      this.snackbarType = type
      this.snackbarText = text
    },
    toggleLocalAudio() {
      if (this.localAudioEnabled) {
        this.room.localParticipant.audioTracks.forEach(publication => {
          publication.track.disable()
          this.localAudioEnabled = false
        })
      } else {
        this.room.localParticipant.audioTracks.forEach(publication => {
          publication.track.enable()
          this.localAudioEnabled = true
        })
      }
    },
    toggleLocalVideo() {
      if (this.localVideoEnabled) {
        this.room.localParticipant.videoTracks.forEach(publication => {
          publication.track.disable()
          this.localVideoEnabled = false
        })
      } else {
        this.room.localParticipant.videoTracks.forEach(publication => {
          publication.track.enable()
          this.localVideoEnabled = true
        })
      }
    },
    startEverything() {
      this.connectTwo()
    },
    connectTwo() {
      createLocalTracks({
        audio: true,
        video: {
          name: 'camera',
          width: { min: 640, ideal: 1280 },
          height: { min: 480, ideal: 720 },
        },
      })
        .then(localTracks => {
          // const localMediaContainer = document.getElementById('local');
          // localMediaContainer.appendChild(track.attach());
          localTracks.forEach(track => {
            if (track.kind == 'video') {
              let video = document.getElementById('local')
              video.appendChild(track.attach())
              video.firstChild.style.width = '100px'
              video.firstChild.style.maxWidth = '100px'
              video.firstChild.style.borderRadius = '4px'
              video.firstChild.style.border = '1px solid white'
              video.firstChild.style.padding = '0px'
              video.firstChild.style.marginTop = '5px'
              video.firstChild.style.transform = 'scale(-1, 1)'

              // give self video a second to ake itself presentable

              let vm = this
              setTimeout(() => {
                vm.selfVideoReady = true
              }, 2000)

              this.localVideoEnabled = true
              this.localAudioEnabled = true
            }
          })
          return connect(this.roomToken, {
            name: 'my-room-name',
            tracks: localTracks,
          })
        })
        .then(room => {
          //// room is now created, handle each participant that exists, and prepare for new participants

          this.room = room
          this.readyForDisplay = true
          this.$emit('roomConnected')

          room.on('participantConnected', participant => {
            vm.inviteeConnected = true
            vm.inviteeDisconnectedFirst = false

            console.log(`Participant "${participant.identity}" connected`)

            this.inviteeConnected = true
            // this is a new participant. that has joined after the user did
            participant.tracks.forEach(publication => {
              if (publication.isSubscribed) {
                const track = publication.track
                let vidContainer = document.getElementById('remote-media-div')
                vidContainer.appendChild(track.attach())
                vidContainer.lastChild.className = 'videoView'
                this.videoResizeObserver.observe(vidContainer.lastChild)

                this.trackEnabled(track)
                let vm = this
                track.on('disabled', track => {
                  vm.trackDisabled(track)
                })
                track.on('enabled', track => {
                  vm.trackEnabled(track)
                })
              }
            })

            participant.on('trackSubscribed', track => {
              this.trackEnabled(track)
              let vm = this
              track.on('disabled', track => {
                vm.trackDisabled(track)
              })
              track.on('enabled', track => {
                vm.trackEnabled(track)
              })

              let vidContainer = document.getElementById('remote-media-div')
              vidContainer.appendChild(track.attach())
              vidContainer.lastChild.className = 'videoView'
              this.videoResizeObserver.observe(vidContainer.lastChild)
            })
          })

          // handle any participants already in the room

          room.participants.forEach(participant => {
            this.inviteeConnected = true
            this.inviteeDisconnectedFirst = false
            participant.tracks.forEach(publication => {
              if (publication.track) {
                let vidContainer = document.getElementById('remote-media-div')
                vidContainer.appendChild(publication.track.attach())
                vidContainer.lastChild.className = 'videoView'
                this.videoResizeObserver.observe(vidContainer.lastChild)

                this.trackEnabled(publication.track)
                let vm = this
                publication.track.on('disabled', track => {
                  vm.trackDisabled(track)
                })
                publication.track.on('enabled', track => {
                  vm.trackEnabled(track)
                })
              }
            })

            participant.on('trackSubscribed', track => {
              let vidContainer = document.getElementById('remote-media-div')
              vidContainer.appendChild(track.attach())
              vidContainer.lastChild.className = 'videoView'
              this.videoResizeObserver.observe(vidContainer.lastChild)

              this.trackEnabled(track)
              let vm = this
              track.on('disabled', track => {
                vm.trackDisabled(track)
              })
              track.on('enabled', track => {
                vm.trackEnabled(track)
              })
            })
          })

          let vm = this
          room.on('participantDisconnected', participant => {
            console.log(
              `Participant "${participant.identity}" has disconnected from the Room`
            )
            // remove the child element
            vm.remoteVideoEnabled = false
            vm.remoteAudioEnabled = false
            vm.inviteeConnected = false
            vm.inviteeDisconnectedFirst = true
            let parentRemoteVideoContainer = document.getElementById(
              'remote-media-div'
            )
            while (parentRemoteVideoContainer.firstChild) {
              parentRemoteVideoContainer.removeChild(
                parentRemoteVideoContainer.firstChild
              )
            }

            //vm.leaveRoom()
          })

          room.on('disconnected', room => {
            // Detach the local media elements
            room.localParticipant.tracks.forEach(publication => {
              const attachedElements = publication.track.detach()
              attachedElements.forEach(element => element.remove())
            })
            this.room = null
          })
        })
        .catch(error => {
          console.error(`Unable to connect to Room: ${error.message}`)
          this.roomErrorMessage = `Unable to connect to Room: ${error.message}`
        })
    },
    cleanIncomingNodes() {
      let vidContainer = document.getElementById('remote-media-div')
      while (vidContainer.firstChild) {
        vidContainer.removeChild(vidContainer.firstChild)
      }
    },
    trackEnabled(track) {
      if (track.kind === 'video') {
        this.remoteVideoEnabled = true

        // check the orientation.
        let width = document.getElementById('remote-media-div').clientWidth

        let height = document.getElementById('remote-media-div').clientHeight

        if (width > height) {
          this.$emit('landscapeOrientation')
        } else {
          this.$emit('portraitOrientation')
        }
      }

      if (track.kind === 'audio') {
        this.remoteAudioEnabled = true
      }
    },
    trackDisabled(track) {
      if (track.kind === 'video') {
        this.remoteVideoEnabled = false
      }

      if (track.kind === 'audio') {
        this.remoteAudioEnabled = false
      }
    },
    leaveRoom() {
      
      this.callEnded = true

      if (get(this, 'room.localParticipant', null)) {
        this.room.localParticipant.videoTracks.forEach(publication => {
          publication.track.stop()
          publication.unpublish()
        })
      }

      if (get(this, 'room', null)) {
        this.room.disconnect()
        this.$emit('roomClosed')
      }
    },
  },
  mounted() {
    this.videoResizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        const cr = entry.contentRect
        // only update the orientation if the remote video is enabled
        if (
          cr.width !== null &&
          cr.width > cr.height &&
          !this.inviteeDisconnectedFirst &&
          this.remoteVideoEnabled
        ) {
          this.$emit('landscapeOrientation')
        } else if (this.remoteVideoEnabled) {
          this.$emit('portraitOrientation')
        }
      }
    })

    this.videoResizeObserver.observe(
      document.getElementById('remote-media-div')
    )
    this.startEverything()

    window.addEventListener('beforeunload', () => {
      this.leaveRoom()
    })
  },
  beforeDestroy() {
    if (get(this, 'room', null)) {
      this.leaveRoom()
      this.$emit('roomClosed')
    }
  },
}
</script>

<style>
.videoView {
  max-height: 100%;
  max-width: 100%;
  min-width: 100%;
  object-fit: contain;
}
</style>
