<template>
  <v-tooltip :disabled="slim" bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
      v-if="!slim"
        v-bind="attrs"
        v-on="on"
        rounded
        color="grey darken-3"
        dark
        depressed
        class="mr-2"
      >
        <v-icon color="white" left class="mr-2">mdi-video</v-icon>
        <span class=" white--text">{{ trackerTitle }}</span>
      </v-btn>
      <v-chip
      v-else
        rounded
        color="#00000030"
        dark
        depressed
        class="mr-1"
        small
        :ripple="false"
      >
        <v-icon v-show="false" color="white" left class="mr-2">mdi-clock-outline</v-icon>
        <span class=" white--text">{{ trackerTitle }}</span>
      </v-chip>
    </template>
    <span>Active Telehealth Session</span>
  </v-tooltip>
</template>

<script>
import { mapState } from 'vuex'
import get from 'lodash/get'

export default {
  data() {
    return {
      currentlyTrackedPatient: null,
      isTracking: true,
      rpmInterval: null,
      clockInterval: null,
      startTimeForSession: null,
      elapsedTime: null,
      fav: true,
      menu: false,
      sessionNotes: null,
      refPoint: null,
      menuKey: 0,
      closeOnClick: false,
      savingNote: false,
    }
  },
  props: {
    slim: {
      default: false
    }
  },
  components: {},
  computed: {
    ...mapState('auth', ['user', 'configuration', 'partner', 'appTheme']),
    ...mapState('patient', ['patient']),
    // ...mapState('patientv2', ['patient']),
    userCanTrackTime() {
      if (
        get(this, 'patient.rpm.enrollment.enrolled', false) &&
        get(this, 'user.partnerRoles.qhp', false)
      ) {
        return true
      }
      return false
    },
    timerTooltip() {
      if (this.isTracking) {
        return 'Stop Timer'
      }
      return 'Start Timer'
    },
    trackerTitle() {
      if (this.isTracking) {
        if (this.elapsedTime) {
          return this.elapsedTime
        } else {
          return null
        }
      }
      return '00:00'
    },
    trackerIcon() {
      if (this.isTracking) {
        return 'mdi-stop'
      }
      return 'mdi-clock-outline'
    },
    buttonColor() {
      if (!this.isTracking) {
        return 'grey'
      }
      return 'green'
    },
  },
  methods: {
    setClock() {
      let self = this
      this.clockInterval = setInterval(function() {
        if (self.startTimeForSession) {
          let data = Math.trunc(
            new Date().getTime() / 1000 - self.startTimeForSession / 1000
          )
          let hours = Math.floor(data / 3600)
          let minutes = Math.floor((data - hours * 3600) / 60) + hours * 60
          let seconds = data - minutes * 60

          let timeString =
            minutes.toString().padStart(2, '0') +
            ':' +
            seconds.toString().padStart(2, '0')
          self.elapsedTime = timeString
        }
      }, 1000)
    },
  },
  mounted() {
    this.elapsedTime = '00:00'
    this.startTimeForSession = new Date().getTime()
    this.setClock()
  },
  beforeDestroy() {
    clearInterval(this.clockInterval)
  },
}
</script>

<style></style>
