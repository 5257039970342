<template>
  <div>
    <v-fade-transition>
      <v-card v-if="patient" width="240" dark flat color="transparent">
        <div v-if="!inviteeConnected">
          <v-row v-if="!inviteeDisconnectedFirst" no-gutters justify="center">
            <span class="text-h5 font-weight-regular "
              >{{ patient.firstName }} {{ patient.lastName }}</span
            >
          </v-row>

          <v-row v-if="inviteeDisconnectedFirst" no-gutters justify="center">
            <span class="text-h6 font-weight-regular red--text"
              >{{ patient.firstName }} disconnected</span
            >
          </v-row>

          <v-row
            v-if="!inviteeDisconnectedFirst"
            no-gutters
            class="px-6 py-1"
            justify="center"
          >
            <span class="text-body-1 font-weight-light text-center">{{
              loadingMessage
            }}</span>
          </v-row>

          <v-row no-gutters justify="center">
            <v-tooltip color="grey darken-3" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  @click="copyJoiningInformation"
                  small
                  class="mt-2 text-none"
                  text
                  rounded
                >
                  {{ inviteeURL }}
                </v-btn>
              </template>
              Copy link to clipboard
            </v-tooltip>
          </v-row>

          <v-row v-if="!inviteeDisconnectedFirst" no-gutters justify="center">
            <v-btn width="160" small class="mt-2" outlined rounded @click="$emit('link:click', inviteeURL)">
              Send link by text
            </v-btn>
          </v-row>

        </div>

        <div class="font-weight-light" v-if="inviteeConnected">
          <v-row v-if="!remoteAudio && remoteVideo" no-gutters justify="center">
            {{ patient.firstName }} is muted
          </v-row>

          <v-row v-if="remoteAudio && !remoteVideo" no-gutters justify="center">
            {{ patient.firstName }}'s' video is off
          </v-row>

          <v-row
            v-if="!remoteAudio && !remoteVideo"
            no-gutters
            justify="center"
          >
            {{ patient.firstName }}'s' video and audio are off
          </v-row>
        </div>
      </v-card>

      <v-card v-else width="240" dark flat color="transparent">
        <div v-if="!inviteeConnected">
          <v-row v-if="!inviteeDisconnectedFirst" no-gutters justify="center">
            <span class="text-h5 font-weight-regular ">Secure Video</span>
          </v-row>

          <v-row v-if="inviteeDisconnectedFirst" no-gutters justify="center">
            <span class="text-h5 font-weight-regular ">Session ended</span>
          </v-row>

          <v-row
            v-if="!inviteeDisconnectedFirst"
            no-gutters
            class="px-6 py-1"
            justify="center"
          >
            <span class="text-body-1 font-weight-light text-center">{{
              loadingMessageNoPatient
            }}</span>
          </v-row>
        </div>

        <div class="font-weight-light" v-if="inviteeConnected">
          <v-row v-if="!remoteAudio && remoteVideo" no-gutters justify="center">
            They're muted
          </v-row>

          <v-row v-if="remoteAudio && !remoteVideo" no-gutters justify="center">
            Their video is off
          </v-row>

          <v-row
            v-if="!remoteAudio && !remoteVideo"
            no-gutters
            justify="center"
          >
            Their video and audio are off
          </v-row>
        </div>
      </v-card>
    </v-fade-transition>
  </div>
</template>

<script>
export default {
  data: () => ({
    showCard: false,
    copiedLink: false,
    inviteeDisconnected: false,
  }),
  props: {
    inviteeToken: {
      default: null,
    },
    inviteeConnected: {
      default: false,
    },
    inviteeDisconnectedFirst: {
      default: false,
    },
    stage: {
      default: 0,
    },
    errorMessage: {
      default: null,
    },
    remoteVideo: {
      default: false,
    },
    remoteAudio: {
      default: false,
    },
    inviteeURL: null,
    patient: null,
  },
  watch: {
    inviteeConnected(val) {
      if (!val) {
        this.inviteeDisconnected
      }
    },
    //   stage(val) {
    //       if (val === 3) {
    //           let vm = this
    //           setTimeout(function() {vm.showCard = false}, 4000)
    //       } else {
    //           let vm = this
    //           setTimeout(function() {vm.showCard = true}, 1000)
    //       }
    //   }
  },
  computed: {
    loadingMessage() {
      if (this.errorMessage) {
        return this.errorMessage
      }

      if (this.stage === 1) {
        return 'Room created. Ready for ' + this.patient.firstName + ' to join.'
      }

      if (this.stage === 2) {
        return 'Participant Connected'
      }

      if (this.stage === 3) {
        return 'Video Loading'
      }
      return 'Connecting to room'
    },
    loadingMessageNoPatient() {
      if (this.errorMessage) {
        return this.errorMessage
      }

      if (this.stage === 1) {
        return 'Room created. Ready for participant to join.'
      }

      if (this.stage === 2) {
        return 'Participant Connected'
      }

      if (this.stage === 3) {
        return 'Video Loading'
      }
      return 'Connecting to room'
    },
  },
  methods: {
    copyDevInviteeLinkInformation() {
      navigator.clipboard
        .writeText('https://staging.novorpm.com/video/' + this.inviteeToken)
        .then(() => {
          this.copiedLink = true
        })
        .catch(error => {
          console.log(`Copy failed! ${error}`)
        })
    },
    copyJoiningInformation() {
      navigator.clipboard
        .writeText(this.inviteeURL)
        .then(() => {
          this.copiedLink = true
        })
        .catch(error => {
          console.log(`Copy failed! ${error}`)
        })
    },
    // sendJoiningInfoByText() {
    //   if (this.$route.name === 'Messages') {
    //     bus.$emit("sendMessageToActivePatient", {
    //     text:
    //       "Please join this telehealth video call using the following link: " +
    //       this.activeVideoCallInviteeLink,
    //   });
    //   } else {
    //     this.$router.push({
    //     name: "Messages",
    //     params: { id: this.patient._id },
    //   }).then(() => {
    //     bus.$emit("sendMessageToActivePatient", {
    //     text:
    //       "Please join this telehealth video call using the following link: " +
    //       this.activeVideoCallInviteeLink,
    //   });
    //   })
    //   }

    // },
  },
  mounted() {
    // make the progress indicator appear gracefully on mount
  },
}
</script>

<style>
.complete {
  width: 55px;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  color: white;
  opacity: 1;
}
.incomplete {
  width: 55px;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  color: white;
  opacity: 0.3;
}
</style>
